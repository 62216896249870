// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useEffect } from 'react';

const REPLAY_EXCLUDED_ORGANIZATIONS: string[] = [];
const REPLAY_EXCLUDED_EMAIL_DOMAINS: string[] = ['redocly.com'];

export const initializeReplay = (organizationId: string, email?: string) => {
  if (REPLAY_EXCLUDED_ORGANIZATIONS.includes(organizationId)) return;
  if (email && REPLAY_EXCLUDED_EMAIL_DOMAINS.includes(email.split('@')[1])) return;

  try {
    const client = Sentry.getClient();
    if (client?.getIntegrationByName('Replay')) return;

    client?.addIntegration?.(
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
      }),
    );

    client?.addIntegration?.(
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useLocation,
        useNavigationType,
        useEffect,
      }),
    );
  } catch (err) {
    console.error('Failed to initialize sentry replay integration', err);
  }
};

// eslint-disable-next-line no-restricted-imports
export * from '@sentry/react';
