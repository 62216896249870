export interface BlacklistItem {
  message: RegExp;
  url?: RegExp;
}

export interface MatcherConfig {
  message: string;
  url: string;
}

export const shouldIgnoreError = (blacklist: BlacklistItem[], matcherConfig: MatcherConfig) =>
  blacklist.some((blacklistItem) =>
    // @ts-ignore
    ['message', 'url'].every((key) => RegExp(blacklistItem[key], 'gi').test(matcherConfig[key])),
  );
