import * as Sentry from '@common/utils/sentry';

import { shouldIgnoreError } from './should-ignore-error';

import type { BlacklistItem, MatcherConfig } from './should-ignore-error';
import type { ErrorEvent, EventHint } from '@sentry/types';

// If you change this list, you need to change the list in the API as well
// /apps/api/src/providers/sentry/before-send.ts
const ALLOW_LIST: string[] = ['prod', 'prodeu'];

const SENTRY_ALERTS_BLACKLIST: BlacklistItem[] = [];

type BeforeSend = (
  event: ErrorEvent,
  hint?: EventHint,
) => PromiseLike<Sentry.ErrorEvent | null> | Sentry.ErrorEvent | null;

const beforeSendHandler: BeforeSend = (event, hint) => {
  const matcherConfig: MatcherConfig = {
    message: (hint?.originalException as Error)?.message || '',
    url: event.request?.url || '',
  };

  return shouldIgnoreError(SENTRY_ALERTS_BLACKLIST, matcherConfig) ? null : event;
};

export function createBeforeSend(env?: string): BeforeSend {
  if (env && !ALLOW_LIST.includes(env)) {
    return () => null;
  }

  return beforeSendHandler;
}
